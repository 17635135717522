<template>
    <b-row>
        <b-col>
            <b-card>
                <!-- media -->
                <b-media no-body>
                    <b-media-aside>
                        <b-link>
                            <b-img ref="previewEl" rounded :src="require('@/assets/images/avatars/13-small.png')"
                                height="80" />
                        </b-link>
                        <!--/ avatar -->
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                        <!-- upload button -->
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm"
                            class="mb-75 mr-75" @click="$refs.refInputEl.$el.click()">
                            Upload
                        </b-button>
                        <b-form-file ref="refInputEl" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true"
                            plain @input="inputImageRenderer" />
                        <!--/ upload button -->

                        <!-- reset -->
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm"
                            class="mb-75 mr-75">
                            Apagar
                        </b-button>
                        <!--/ reset -->
                        <b-card-text>Permitido JPG, GIF ou PNG. Max 500KB</b-card-text>
                    </b-media-body>
                </b-media>
                <!--/ media -->

                <!-- form -->
                <b-form class="mt-2">
                    <b-row>

                        <b-col sm="6">
                            <b-form-group label="Nome" label-for="account-name">
                                <b-form-input v-model="optionsLocal.fullName" name="name" placeholder="Nome" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group label="E-mail" label-for="account-e-mail">
                                <b-form-input v-model="optionsLocal.email" name="email" placeholder="Email" />

                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group label="Celular" label-for="account-mobile">
                                <b-form-input v-model="optionsLocal.username" placeholder="(11) 99999-9999"
                                    name="username" />
                            </b-form-group>
                        </b-col>

                        <b-col sm="6">
                            <b-form-group label="Empresa" label-for="account-company">
                                <b-form-input v-model="optionsLocal.company" name="Empresa"
                                    placeholder="Empresa" />
                            </b-form-group>
                        </b-col>

                        <!-- alert -->
                        <!-- <b-col cols="12" class="mt-75">
                            <b-alert show variant="warning" class="mb-50">
                                <h4 class="alert-heading">
                                    Your email is not confirmed. Please check your inbox.
                                </h4>
                                <div class="alert-body">
                                    <b-link class="alert-link">
                                        Resend confirmation
                                    </b-link>
                                </div>
                            </b-alert>
                        </b-col> -->
                        <!--/ alert -->

                        <b-col cols="12">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1">
                                Atualizar Dados
                            </b-button>
                            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary"
                                type="reset" class="mt-2" @click.prevent="resetForm">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>

            <b-card>
                <!-- form -->
                <b-form>
                    <b-row>
                        <!-- old password -->
                        <b-col md="6">
                            <b-form-group label="Senha atual" label-for="account-old-password">
                                <b-input-group class="input-group-merge">
                                    <b-form-input id="account-old-password" v-model="passwordValueOld"
                                        name="old-password" :type="passwordFieldTypeOld" placeholder="Senha atual" />
                                    <b-input-group-append is-text>
                                        <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer"
                                            @click="togglePasswordOld" />
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <!--/ old password -->
                    </b-row>
                    <b-row>
                        <!-- new password -->
                        <b-col md="6">
                            <b-form-group label-for="account-new-password" label="Nova Senha">
                                <b-input-group class="input-group-merge">
                                    <b-form-input id="account-new-password" v-model="newPasswordValue"
                                        :type="passwordFieldTypeNew" name="new-password" placeholder="Nova senha" />
                                    <b-input-group-append is-text>
                                        <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer"
                                            @click="togglePasswordNew" />
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <!--/ new password -->

                        <!-- retype password -->
                        <b-col md="6">
                            <b-form-group label-for="account-retype-new-password" label="Repetir Senha">
                                <b-input-group class="input-group-merge">
                                    <b-form-input id="account-retype-new-password" v-model="RetypePassword"
                                        :type="passwordFieldTypeRetype" name="retype-password"
                                        placeholder="Repetir senha" />
                                    <b-input-group-append is-text>
                                        <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer"
                                            @click="togglePasswordRetype" />
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <!--/ retype password -->

                        <!-- buttons -->
                        <b-col cols="12">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1">
                                Alterar Senha
                            </b-button>
                            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset"
                                variant="outline-secondary" class="mt-1">
                                Cancelar
                            </b-button>
                        </b-col>
                        <!--/ buttons -->
                    </b-row>
                </b-form>
            </b-card>

        </b-col>
    </b-row>


</template>
  
<script>
import {
    BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
    components: {
        BButton,
        BForm,
        BImg,
        BFormFile,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BAlert,
        BCard,
        BCardText,
        BMedia,
        BMediaAside,
        BMediaBody,
        BLink,
    },
    directives: {
        Ripple,
    },
    // props: {
    //   generalData: {
    //     type: Object,
    //     default: () => {},
    //   },
    // },
    data() {
        return {
            optionsLocal: [],
            //optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
            profileFile: null,
        }
    },
    // methods: {
    //   resetForm() {
    //     this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    //   },
    // },
    setup() {
        const refInputEl = ref(null)
        const previewEl = ref(null)

        const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

        return {
            refInputEl,
            previewEl,
            inputImageRenderer,
        }
    },
}
</script>
  